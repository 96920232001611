import React from 'react'
import styled from 'styled-components'

import GroupItem from './GroupItem'

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;

  @media only screen and (min-width: 1024px) {
    position: absolute;
    width: calc(100% - 80px);
  }
`

const Group = ({ data, show, activeFilter, setActiveFilter, setActiveBtn }) => {
  return (
    <Wrapper show={show}>
      {data.map((item, i) => {
        return (
          <GroupItem
            item={item}
            key={i}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            setActiveBtn={setActiveBtn}
            index={i}
          />
        )
      })}
    </Wrapper>
  )
}

export default Group
