import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import GraphImg from 'graphcms-image'
import { useSpring, animated } from 'react-spring'

const Wrapper = styled.li`
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    width: calc(100% / 2);
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1200px) {
    width: calc(100% / 3);
  }
`

const TextWrapper = styled.div`
  display: inline-block;
  transition: filter 0.3s ease-out, transform 0.15s ease-out;
  filter: ${({ active }) => (active ? 'blur(0px)' : 'blur(2px)')};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  position: relative;

  @media only screen and (min-width: 1024px) {
    padding: 10px;
  }

  @media (hover: hover) {

    &:hover {
      transform: scale(1.15);
      z-index: 2;
    }
  }
`

const NameWrapper = styled.div`
  padding: 7px 30px;
  transition: transform 0.15s ease-out;

  @media only screen and (min-width: 768px) {
    padding: 7px 20px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7px 30px;
  }
`

const Name = styled.h3`
  font-size: 18px;
  letter-spacing: 1.46px;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
    letter-spacing: 1.66px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 1.86px;
  }
`

const Loc = styled.span`
  display: block;
  font-size: 11px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fontSansMedium};
  transition: opacity 0.15s ease-in-out;

  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    line-height: 1.3;
    padding-top: 5px;
  }

  @media (hover: hover) and (min-width: 1024px) {
    opacity: 0;
    pointer-events: none;

    ${TextWrapper}:hover & {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const Image = styled.div`
  display: none;

  & > div {
    height: 50vh;
    width: calc((100vw - 80px) / 3);
  }

  & > div > div {
    height: inherit;
    width: inherit;
  }

  img {
    height: 50vh !important;
    width: calc((100vw - 80px) / 3) !important;
    object-fit: contain !important;
    object-position: center !important;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: ${({ imagePosX }) => (imagePosX ? imagePosX : 'center')};
    align-items: ${({ imagePosY }) => (imagePosY ? imagePosY : 'center')};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    height: 100vh;
    width: 100%;
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    pointer-events: none;
    padding: ${({ imagePadd }) => imagePadd}vh;
  }

  @media (hover: hover) and (min-width: 1024px) {
    opacity: ${({ show }) => (show ? 1 : 0)};
  }
`

const Designer = ({
  data,
  activeFilter,
  hoveredName,
  setHoveredName,
  index,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [imagePosX, setImagePosX] = useState('center')
  const [imagePosY, setImagePosY] = useState('center')
  const [imagePadd, setImagePadd] = useState(3)

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 1, tension: 210, friction: 40 },
  }))

  const calc = (x, y, target) => {
    const left = target.getBoundingClientRect().left
    const top = target.getBoundingClientRect().top

    return [
      -(y - top - target.offsetHeight / 2) / 3,
      (x - left - target.offsetWidth / 2) / 16,
      1.3,
    ]
  }

  const transImage = (x, y, s) =>
    `perspective(900px) rotateX(${x}deg) rotateY(${y}deg) scale(1)`

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const handleMouseEnter = e => {
    let posX = []
    const posY = ['flex-start', 'flex-end']
    const elPos = e.target.getBoundingClientRect().left
    const winWidth = window.innerWidth

    if (elPos < winWidth / 3) {
      posX = ['center', 'flex-end']
    } else if (elPos < winWidth / 2) {
      posX = ['flex-start', 'flex-end']
    } else {
      posX = ['center', 'flex-start']
    }

    setImagePosX(posX[Math.floor(Math.random() * posX.length)])
    setImagePosY(posY[Math.floor(Math.random() * posY.length)])
    setImagePadd(randomIntFromInterval(3, 30))

    setShowImage(true)

    setHoveredName(index)
  }

  const handelScroll = () => {
    setShowImage(false)
  }

  useEffect(() => {
    if (
      (data.filterLocation && activeFilter === data.filterLocation.name) ||
      (data.filterMaterials &&
        data.filterMaterials.some(el => el.name === activeFilter)) ||
      !activeFilter
    ) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [activeFilter, data])

  useEffect(() => {
    window.addEventListener('scroll', handelScroll)
    return () => {
      window.removeEventListener('scroll', handelScroll)
    }
  }, [])

  return (
    <Wrapper className={'no-placeholder'}>
      <TextWrapper
        active={isActive}
        onMouseEnter={e => handleMouseEnter(e)}
        onMouseLeave={() => {
          setShowImage(false)
        }}
      >
        <Link to={`/designers/${data.slug}/`}>
          <NameWrapper
            onMouseMove={({ clientX: x, clientY: y, target }) => {
              set({ xys: calc(x, y, target) })
            }}
            onMouseLeave={() => {
              set({ xys: [0, 0, 1] })
            }}
          >
            <Name>{data.name}</Name>
            <Loc>{data.location}</Loc>
          </NameWrapper>
        </Link>
      </TextWrapper>

      <Image
        show={showImage}
        imagePosX={imagePosX}
        imagePosY={imagePosY}
        imagePadd={imagePadd}
      >
        <animated.div
          style={{
            transform: props.xys.interpolate(transImage),
          }}
        >
          <GraphImg
            image={data.portrait}
            backgroundColor={'transparent'}
            maxWidth={400}
            blurryPlaceholder={false}
          />
        </animated.div>
      </Image>
    </Wrapper>
  )
}

export default Designer
