import React from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'

import List from '../components/designers/List'

const Designers = props => {
  return (
    <div>
      <SEO title="Designers" />
      <List data={props.data.dis} />
    </div>
  )
}

export default Designers

export const query = graphql`
  query DesignersQuery {
    dis {
      pageDesigner(where: {id: "cki61v0sg0yfv0951nc99r2o6"})  {
        designers {
          ... on DIS_Designer {
            id
            name
            slug
            portrait {
              height
              handle
              width
            }
            location
            seoDescription
            seoImage {
              url
            }
            seoTitle
            filterLocation {
              name
            }
            filterMaterials {
              name
            }
          }
        }
      }
      filterLocations {
        name
      }
      filterMaterials {
        name
      }
    }
  }
`
