import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import PlusSvg from '../../assets/plus.svg'

const Inner = styled.button`
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 10px;
  opacity: ${({ act }) => (act ? 1 : 0.2)};
  position: relative;
  padding: 0 10px;

  @media only screen and (min-width: 1024px) {
    margin: 10px 20px;
  }
`

const Close = styled(PlusSvg)`
  height: 6px;
  width: 6px;
  position: absolute;
  top: 6px;
  right: -4px;
  transform: rotate(45deg);
`

const GroupItem = ({
  item,
  activeFilter,
  setActiveFilter,
  index,
  setActiveBtn,
}) => {
  const [isActive, setIsActive] = useState(false)

  const handleFilterClick = active => {
    if (active) {
      setActiveBtn(0)
      setActiveFilter(false)
    } else {
      setActiveFilter(item.name)
    }
  }

  useEffect(() => {
    setIsActive(activeFilter === item.name)
  }, [activeFilter, item.name])

  return (
    <li>
      <Inner act={isActive} onClick={() => handleFilterClick(isActive)}>
        {item.name} {isActive && <Close />}
      </Inner>
    </li>
  )
}

export default GroupItem
