import React from 'react'
import styled from 'styled-components'

import PlusSvg from '../../assets/plus.svg'

import Group from './Group'

const Wrapper = styled.div`
  margin-bottom: 50px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 80px;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const FilterButton = styled.button`
  font-size: 12px;
  line-height: 28px;
  padding: 2px 15px 2px;
  border: 1px solid ${({ theme }) => theme.black};
  border-radius: 17px;
  font-family: ${({ theme }) => theme.fontSansMedium};
  text-transform: uppercase;
  margin: 0 5px;
  /* TODO: uncomment with the filter buttons below whe necessary */
  /* opacity: ${({ act }) => (act ? 1 : 0.2)}; */

  @media only screen and (min-width: 1024px) {
    padding: 1px 15px 3px;
  }
`

const Plus = styled(PlusSvg)`
  height: 7px;
  width: 7px;
  position: relative;
  top: -1px;
  margin-left: 2px;
  transition: transform 0.15s;
  transform: ${({ act }) => (act ? 'rotate(45deg)' : 'rotate(0)')};
`

const Filters = ({
  locations,
  materials,
  activeBtn,
  setActiveBtn,
  activeFilter,
  setActiveFilter,
}) => {
  const handleButtonClick = num => {
    if (activeFilter && activeBtn === num) {
      setActiveFilter(false)
      setActiveBtn(0)
    } else {
      setActiveFilter(false)
      setActiveBtn(num)

      if (num === 1) {
        setActiveFilter(locations[0].name)
      } else if (num === 2) {
        setActiveFilter(materials[0].name)
      } else {
        setActiveFilter(false)
      }
    }
  }

  return (
    <Wrapper>
      <Buttons>
          <FilterButton
          act={activeBtn === 0}
          onClick={() => handleButtonClick(0)}
          >
          All
          </FilterButton>
        <FilterButton
          act={activeBtn === 1}
          onClick={() => handleButtonClick(1)}
        >
          Location <Plus act={activeBtn === 1} />
        </FilterButton>
          <FilterButton
          act={activeBtn === 2}
          onClick={() => handleButtonClick(2)}
          >
          Material <Plus act={activeBtn === 2} />
          </FilterButton>
      </Buttons>

      <Group
        show={activeBtn === 1}
        data={locations}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setActiveBtn={setActiveBtn}
      />
      <Group
        show={activeBtn === 2}
        data={materials}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setActiveBtn={setActiveBtn}
      />
    </Wrapper>
  )
}

export default Filters
