import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import W from '../styled/Wrapper'

import Designer from './Designer'
import Filters from './Filters'

const Wrapper = styled(W)`
  padding-top: 110px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.black};

  @media only screen and (min-width: 1024px) {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`

const Spacer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: auto;
  height: auto;
`

const DesignersWrapper = styled.ul`
  list-style: none;
  margin: 0;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media only screen and (min-width: 1200px) {
    flex-grow: 1;
    max-height: 660px;
  }
`

const List = ({ data }) => {
  const { filterLocations, filterMaterials, pageDesigner } = data

  const [activeBtn, setActiveBtn] = useState(0)
  const [activeFilter, setActiveFilter] = useState(false)
  const [hoveredName, setHoveredName] = useState(false)

  return (
    <Wrapper as="section">
      <Filters
        locations={filterLocations}
        materials={filterMaterials}
        activeBtn={activeBtn}
        setActiveBtn={setActiveBtn}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <Spacer>
        <DesignersWrapper>
          {pageDesigner.designers.map((item, i) => {
            return (
              <Designer
                data={item}
                key={i}
                activeFilter={activeFilter}
                index={i}
                hoveredName={hoveredName}
                setHoveredName={setHoveredName}
              />
            )
          })}
        </DesignersWrapper>
      </Spacer>
    </Wrapper>
  )
}

export default List
